.Download-App {
  background-image: url("../../../../public/Assets/Images/about/app-banner.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 50px 0px;
  margin-top: 5%;
}

.Download-App .heading-holder h2 {
  font-size: 35px;
  font-weight: bold;
}

.Download-App .border-line {
  border-bottom: 2px solid #0b6e99;
}

.mobileimgdiv {
    text-align: center;
}

.Download-App .mobileimgdiv .mobileimg {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 0.8;
  object-fit: contain;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.Download-App .mobileimgdiv:hover .mobileimg {
    transform: scale(1.1);
    opacity: 0.9;
}

@media(min-width:0px) and (max-width:767px) {
    .Download-App .heading-holder h2 {
        font-size: 20px;
        text-align: center;
    }

    .Download-App .heading-holder p {
        font-size: 14px;

    }

    .Download-App .button-holder {
        margin-bottom: 30px;
    }

    .Download-App .mobileimgdiv .mobileimg {
        width: 50%;
      }
}

@media(min-width:768px) and (max-width:992px) {
    .Download-App .heading-holder h2 {
        font-size: 25px;

    }

    .Download-App .heading-holder p {
        font-size: 14px;

    }

    .Download-App .button-holder {
        margin-bottom: 30px;
    }

    .Download-App .mobileimg {
        margin-top: 36px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .Download-App .heading-holder h2 {
        font-size: 30px;

    }

    .Download-App .heading-holder p {
        font-size: 14px;

    }
}
