.How_to_Get_Started {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  padding: 5% 0%;
  margin-top: 5%;
}

.How_to_Get_Started .How_to_Get_Started_holder .heading-holder .subheading {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #b1b1b1;
  margin-top: 15%;
  margin-bottom: 0px;
}

.How_to_Get_Started_cards .card-holder .img-holder {
  position: relative;
}

.How_to_Get_Started_cards .card-holder .img-holder .card-image-holder {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}

.How_to_Get_Started_cards .card-holder .img-holder .img-orignal {
  display: none;
}

.How_to_Get_Started_cards .card-holder .img-holder .img-hover {
  display: block;
}

.How_to_Get_Started_cards .card-holder .img-holder:hover .img-orignal {
  display: block;
}

.How_to_Get_Started_cards .card-holder .img-holder:hover .img-hover {
  display: none;
}

.How_to_Get_Started_cards .card-holder .img-holder:hover .icon-holder {
  top: 50px;
  transition: all 0.2s ease-in-out;
}

.How_to_Get_Started_cards .card-holder .img-holder .icon-holder {
  width: fit-content;
  margin: 0px auto;
  position: absolute;
  top: 35px;
  left: 0px;
  right: 0px;
  display: block;
  transition: all 0.2s ease-in-out;
}

.How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
  color: #fff;
  font-size: 30px;
}

.How_to_Get_Started_cards .after-border {
  position: absolute;
  content: "";
  border-bottom: 1px dashed #0b6e99;
  width: 95%;
  top: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 87%;
  transform: translateY(-50%);
}

.How_to_Get_Started_cards .after-border:before {
  position: absolute;
  content: "";
  background: #0b6e99;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  left: 0px;
}

.How_to_Get_Started_cards .after-border:after {
  position: absolute;
  content: "";
  background: #0b6e99;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  right: 0px;
}

.How_to_Get_Started_cards .card-holder .text-holder p {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #202020;
}

.How_to_Get_Started_cards .card-holder .img-holder {
  margin-bottom: 15px;
  padding: 13px 40px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 10px 95px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    display: none;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 10px 120px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    display: none;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 376px) and (max-width: 485px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 10px 150px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    display: none;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 10px 200px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    display: none;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 576px) and (max-width: 677px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 20px 7px;
  }

  .How_to_Get_Started .How_to_Get_Started_holder .heading-holder .subheading {
    margin-top: 5%;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 90%;
    left: 98%;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 678px) and (max-width: 767px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 20px 7px;
  }

  .How_to_Get_Started .How_to_Get_Started_holder .heading-holder .subheading {
    margin-top: 5%;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 90%;
    left: 98%;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 20px 7px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder .icon-holder .icon {
    font-size: 20px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 90%;
    left: 98%;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 15px 15px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 90%;
    left: 93%;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 15px 15px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 90%;
    left: 93%;
  }

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder:hover .icon-holder {
    top: 50px;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 15px 15px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 85%;
    left: 93%;
}

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder:hover .icon-holder {
    top: 70px;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .How_to_Get_Started_cards .card-holder .img-holder {
    padding: 15px 15px;
  }

  .How_to_Get_Started_cards .after-border {
    width: 85%;
    left: 93%;
}

  .How_to_Get_Started_cards .card-holder .text-holder p {
    font-size: 15px;
  }

  .How_to_Get_Started_cards .card-holder .img-holder:hover .icon-holder {
    top: 70px;
}
}
