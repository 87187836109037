@import url("https://fonts.googleapis.com/css2?family=Open Sans:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", "sans-serif", "SourceSansPro-Light.otf" !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.main-heading {
  font-size: 18px;
  font-weight: 600;
  color: #0b6e99;
  text-transform: uppercase;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 10px;
}

.main-heading:after {
  content: "";
  position: absolute;
  top: 45%;
  bottom: 50%;
  width: 100px;
  border-bottom: 2px solid #0b6e99;
  margin-left: 20px;
}

.heading {
  font-size: 36px;
  font-weight: 700;
  color: #202020;
}

.content-text {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.23px;
  color: #202020;
}

@media (min-width: 0px) and (max-width: 767px) {
  .main-heading {
    font-size: 15px;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    color: #202020;
  }

  .content-text {
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-heading {
    font-size: 15px;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    color: #202020;
  }

  .content-text {
    font-size: 13px;
  }
}


@media (min-width: 992px) and (max-width: 1023px) {
  .main-heading {
    font-size: 15px;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    color: #202020;
  }

  .content-text {
    font-size: 13px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main-heading {
    font-size: 15px;
  }

  .heading {
    font-size: 20px;
    font-weight: 700;
    color: #202020;
  }

  .content-text {
    font-size: 13px;
  }
}