.our-team {
    margin: 5% 0;
}

.our-team .heading-holder h2 {
    color: #0B6E99;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    width: fit-content;

}

.our-team .heading-holder h2::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #0B6E99;
    position: absolute;
    right: -65px;
    z-index: -2;
    margin-top: -12px;
}

.our-team .heading-holder h3 {
    font-size: 40px;
    font-weight: bold;
    margin-top: 15px;
}

.our-team .team-holder-box {
    position: relative;
}

.our-team .team-holder-box .team-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1.1;
    border-radius: 15px;
}

.our-team .team-holder-box .overlay-color {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 40%;
    background: transparent linear-gradient(180deg, #00000000 0%, #000000 73%, #000000 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 15px 15px;
    z-index: 0;
}

.our-team .text-holder {
    position: absolute;
    bottom: 50px;
    z-index: 1;
    left: 40px;
    background: linear-gradient(to right, #10897D 0%, #10897D 52%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.3s ease-in-out, color 0.3s ease-in-out, bottom 0.3s ease-in-out;
    width: 90%;
    transition: 0.3s ease-in-out;
  
}

.our-team .team-holder-box:hover .text-holder {
    background-position: left bottom;
    bottom: 59px;
    background: transparent linear-gradient(90deg, #10897D 0%, #0081E633 100%) 0% 0% no-repeat padding-box;
    /* Smooth transition applied to bottom property */
    padding: 20px 0px;
    transition: 0.3s ease-in-out;
}

.our-team .text-holder h4 {
    color: #FFF;
    font-size: 20px;
    font-weight: 600;
}

.our-team .text-holder h5 {
    color: #FFF;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 0px;
}

.our-team .social-icon-holder {
    display: flex;
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 51px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, bottom 0.3s ease-in-out;
}

.our-team .team-holder-box:hover .social-icon-holder {
    opacity: 1;
    bottom: 29px;
    /* Smooth transition applied to bottom property */
}

.our-team .icon-holder .icon {
    color: #10897D;
    font-size: 11px;
}

.our-team .social-icon-holder .icon-holder {
    margin-right: 10px;
    background: #fff;
    width: 20px;
    height: 20px;
    font-size: large;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50px;
}

@media(min-width:0px) and (max-width:767px) {
    .our-team .heading-holder h3 {
        font-size: 20px;
    }

    .our-team .text-holder h4 {
        font-size: 17px;
    }

    .our-team .text-holder {
        left: 30px;
    }

  
}

@media(min-width:768px) and (max-width:992px) {
    .our-team .heading-holder h3 {
        font-size: 25px;
    }

    /* .our-team .heading-holder h2::after {
        left: 26%;
    } */

    .our-team .text-holder {
        left: 34px;
    }

    .our-team .text-holder h4 {
        font-size: 17px;
    }

    .our-team .social-icon-holder {
        left: 45px;
    }
}

@media(min-width:992px) and (max-width:1200px) {
    .our-team .heading-holder h3 {
        font-size: 30px;
    }

    .our-team .text-holder {
        width: 86%;
    }
}