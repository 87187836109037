.Successfull_Modal .modal-header {
    border: none;
}

.Successfull_Modal .modal-body {
    border: none;
}

.Successfull_Modal .modal-content {
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    opacity: 1;
    backdrop-filter: blur(41px);
    -webkit-backdrop-filter: blur(41px);
}

.modal-backdrop.show {
    opacity: 0.5;

}

.Successfull_Modal .modal-body p {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.Successfull_Modal .modal-body h4 {
    font-size: 14px;
    font-weight: bold;
    color: #F99B1A;
    text-decoration: underline;
}

@media(min-width:0px) and (max-width:767px) {
    .Successfull_Modal .modal-body p {
        font-size: 16px;

    }
}

@media(min-width:768px) and (max-width:992px) {
    .Successfull_Modal .modal-body p {
        font-size: 18px;

    }
}