.privacy-policy {
    margin: 1% 0;
}

.privacy-policy .heading-holder h2 {
    font-size: 32px;
    font-weight: bold;
    color: #000;
}

.privacy-policy .text-holder h3 {
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.privacy-policy .text-holder p {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.privacy-policy .text-holder li {
    font-size: 15px;
    color: #000;
    font-weight: 500;
}
.privacy-policy .text-holder .list-holder li strong{
    color: #000;
}

.privacy-policy .text-holder .list-holder li::marker {
    font-weight: 800;
}

@media(min-width:0px) and (max-width:767px) {
    .privacy-policy .heading-holder h2 {
        font-size: 22px;
    }

    .privacy-policy .text-holder h3 {
        font-size: 17px;
        font-weight: bold;
    }

    .privacy-policy .text-holder p {
        font-size: 14px;
    }
}