.Contact {
  margin-top: 30px;
  margin-bottom: 150px;
}

.Contact .contact_section .contact_main .heading-holder {
  text-align: center;
  margin-bottom: 3%;
}




.Contact .contact_section .fonticndiv {
  width: 32px;
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 50%;
  color: #0B6E99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Contact .contact_section .form-holder-contact{
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.Contact .map-form-holder{
  position: relative;
}

.Contact .map-form-holder .close-btn {
  position: absolute;
  top: -30px;
  right: 0px;
  margin-left: auto;
  width: -moz-fit-content;
  width: fit-content;
}



 .contact_section .leftdivv {
  height: 100%;
  width: 100%;
  padding: 65px 70px 65px 70px;
}

.Contact .contact_section .leftdivv1 {
  height: 100%;
  width: 100%;
  padding: 30px;
  background-color: #0B6E99;
  border-radius: 10px;
}

.Contact .contact_section .leftdivv1 .side-section{
  margin-top: 30px;
}

.Contact .contact_section .btnnnn {
  background: #707070 0% 0% no-repeat padding-box;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}


.Contact .contact_section .btnnnn:hover {
  background: #000 0% 0% no-repeat padding-box;
}

.Contact .contact_section .btndiv {
  padding-top: 20px;
}

.Contact .contact_section .contactus {
  font-size: 70px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #202020;
}

.Contact .contact_section .cont_text {
  font-size: 13px;
  font-weight: 300;
  color: #ffffff;
  font-family: "Montserrat";
}

.Contact .contact_section .contact_section {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0px;
}

.Contact .contact_section input#exampleForm\.ControlInput1 {
  border: none;
  border-bottom: 1px solid #616161;
  border-radius: 0px;
  font-size: 15px;
  color: #000;
  font-family: "Roboto", sans-serif;
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 0;
}

.Contact .contact_section textarea#exampleForm\.ControlTextarea1 {
  border: none;
  border-bottom: 1px solid #616161;
  border-radius: 0px;
  font-size: 15px;
  color: #000;
  font-family: "Roboto", sans-serif;
  background: #F1F1F1 0% 0% no-repeat padding-box;
}

.Contact .contact_section .react-tel-input {
  background: #f1f1f1 0% 0% no-repeat padding-box;
  border-radius: 0px;
}

.Contact .contact_section .react-tel-input .form-control {
    border: none;
    border-bottom: 1px solid #616161;
    border-radius: 0px;
    width: 100%;
    color: #000;
    font-family: "Roboto", sans-serif;
    background: #F1F1F1 0% 0% no-repeat padding-box;
}

.Contact .contact_section .react-tel-input .form-control:focus {
    background: #F1F1F1 0% 0% no-repeat padding-box;

  box-shadow: none;
}
.Contact .contact_section .react-tel-input .flag-dropdown {
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border: none;
    border-bottom: 1px solid #616161;
    border-radius: 0px;
}
.Contact iframe.gmap_iframe {
  width: 100%;
  height: 100%;
}

/* .map_section iframe.gmap_iframe {
    width: 100%;
   
    height: 100%;
} */

.Contact .hidden {
  /* height: 0px; */
  /* display: none; */
  /* -webkit-animation: scale-down-ver-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-ver-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.Contact .visible {
  /* height: 100%; */
  /* display: block; */
  /* -webkit-animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both; */
}

/* @-webkit-keyframes scale-down-ver-bottom {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(0.3);
              transform: scaleY(0.3);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  @keyframes scale-down-ver-bottom {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(0.3);
              transform: scaleY(0.3);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  } */

/* .map_section {
    position: absolute;
    width: 100%;
    bottom: 0;
    }  */
.Contact .gmap_canvas {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 0;
  /* transition: max-height 2s ease-in-out; */
  /* transition: max-height 2s ease-in-out, transform 2s ease-in-out; */
  transition: height 0.4s ease-in-out;
  animation: swing-top-fwd 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.Contact .gmap_canvas.show {
  height: 100% !important;
  /* transition: max-height 2s ease-in-out; */
  /* transition: max-height 2s ease-in-out, transform 2s ease-in-out; */
  /* You can adjust this value based on the map height */
}

.Contact .angleee {
  position: absolute;
  width: 100%;
  background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  height: 129px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Contact .angleicnss {
  font-size: 40px;
}

@media (min-width: 0px) and (max-width: 485px) {
  .Contact .contact_section .leftdivv {
    padding: 30px 20px 20px 20px;
  }

  .Contact .contact_section .btndiv {
    padding-top: 30px;
  }

  .Contact .contact_section .contactus {
    font-size: 30px;
  }
  .Contact .angleicnss {
    font-size: 20px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .Contact .contact_section .leftdivv {
    padding: 30px 20px 20px 20px;
  }

  .Contact .contact_section .btndiv {
    padding-top: 30px;
  }

  .Contact .contact_section .contactus {
    font-size: 40px;
  }
  .Contact .angleicnss {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Contact .contact_section .leftdivv {
    padding: 30px 20px 20px 20px;
  }

  .Contact .contact_section .contactus {
    font-size: 50px;
  }
  .Contact .angleicnss {
    font-size: 28px;
  }
}

.swing-top-fwd {
	-webkit-animation: swing-top-fwd 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: swing-top-fwd 0.4s cubic-bezier(0.250, 0, 0.450, 0.940) both;
}

@-webkit-keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
