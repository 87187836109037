.Header {
  /* background: #fff;
  box-shadow: 0px 0px 7px 1px #0000002b;
  padding: 0px 0px 30px 0px; */
  position: sticky;
  top: 0;
  z-index: 999;
  background-image: url("/public/Assets/Images/Header/Header-bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 30px;
  /* position: relative; */
  filter: drop-shadow(0px 0px 6px #ccc);
}

.Header .Header_holder .icon-text-holder {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 46px;
}

.Header .Header_holder .icon-text-holder p {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: #202020;
  margin-bottom: 0px;
}

.Header .Header_holder .icon-text-holder .icon-holder {
  margin-right: 5px;
}

.Header .Header_holder .icon-holder {
  background: #202020;
  width: 20px;
  height: 20px;
  font-size: large;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 50px;
}

.Header .Header_holder .icon-holder:hover {
  background: #0b6e99;
}

.Header .Header_holder .icon-holder .icon {
  color: #fff;
  font-size: 11px;
  margin-bottom: 2px;
}

.Header .Header_holder .social-icon-holder {
  display: flex;
  justify-content: end;
  align-items: center;
  height: 46px;
}

.Header .Header_holder .social-icon-holder .icon-holder {
  margin-right: 10px;
}

.Header .Header_holder .logo-holder {
  text-align: center;
}

.Header .Header_holder .logo-holder .logo-img {
  width: 60%;
  height: 60%;
  margin: 5% 0% 0% 0%;
}

/* menu-header-holder css start  */

.Header .menu-header-holder {
  /* position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  z-index: 1; */
}

.Header .menu-header-holder .menu-header {
  flex-wrap: nowrap;
  justify-content: center;
  width: fit-content;
  margin: 0% auto 0% auto;
}

.Header .menu-header-holder .menu-header .nav-link {
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  padding: 5px 0px;
  margin: 0px 50px;
}

.Header .menu-header-holder .menu-header .nav-link.active:after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 15px;
  border: 2px solid #10897d;
  left: 0px;
}

.Header .menu-header-holder .menu-header .nav-link.active {
  font-weight: 700;
  position: relative;
}

.Header .menu-header-holder .menu-header .navbar-toggler {
  font-size: 15px;
  border: 0;
  color: #fff;
  filter: brightness(20);
  opacity: 1;
}

.Header .menu-header-holder .menu-header .navbar-toggler:focus {
  box-shadow: none;
}

.Header .mobile-view-toggle-menubar .navbar-collapse {
  right: 0px;
  position: absolute;
  background: #fff;
  width: 200px;
  padding: 5px;
}

/* menu-header-holder css end  */

/* toggle-menubar css view start */

.mobile-view-toggle-menubar {
  display: none;
}

.desktop-view-toggle-menubar {
  display: block;
}

/* toggle-menubar css view end */
@media (min-width: 0px) and (max-width: 991px) {
  .Header {
    background: #fff;
    box-shadow: 0px 0px 7px 1px #0000002b;
    padding: 0px 0px 30px 0px;
    background-image: none;
  }

  .Header .menu-header-holder .menu-header .navbar-toggler {
    filter: brightness(0);
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .Header .mobile-view-toggle-menubar {
    display: block;
    display: flex;
    justify-content: end;
    align-items: end;
  }

  .desktop-view-toggle-menubar {
    display: none;
  }

  .Header {
    padding: 5px 0px 5px 0px;
  }

  .Header .menu-header-holder .menu-header {
    margin: 0px 0px 0px auto;
  }

  .Header .Header_holder .logo-holder {
    text-align: left;
  }

  .Header .mobile-view-toggle-menubar .navbar-collapse {
    top: 117%;
  }

  .Header .menu-header-holder .menu-header .nav-link {
    margin: 0px;
  }
}

@media (min-width: 0px) and (max-width: 320px) {
  .Header .Header_holder .logo-holder .logo-img {
    width: 60%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 100%;
  }

  .Header .Header_holder .icon-text-holder {
    height: 100%;
  }

  .Header .menu-header-holder .menu-header .nav-link {
    font-size: 12px;
    margin: 0px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Header .Header_holder .logo-holder .logo-img {
    width: 45%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 100%;
  }

  .Header .Header_holder .icon-text-holder {
    height: 100%;
  }

  .Header .menu-header-holder .menu-header .nav-link {
    font-size: 12px;
    margin: 0px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Header .Header_holder .logo-holder .logo-img {
    width: 40%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 100%;
  }

  .Header .Header_holder .icon-text-holder {
    height: 100%;
  }

  .Header .menu-header-holder .menu-header .nav-link {
    font-size: 12px;
    margin: 0px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Header .mobile-view-toggle-menubar {
    display: block;
  }

  .desktop-view-toggle-menubar {
    display: none;
  }

  .Header {
    padding: 5px 0px 5px 0px;
  }

  .Header .menu-header-holder .menu-header {
    margin: 0px 0px 0px auto;
  }

  .Header .Header_holder .logo-holder {
    text-align: center;
  }

  .Header .Header_holder .logo-holder .logo-img {
    width: 100%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 100%;
  }

  .Header .Header_holder .icon-text-holder {
    height: 100%;
  }

  .Header .menu-header-holder .menu-header .nav-link {
    font-size: 12px;
    margin: 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Header .mobile-view-toggle-menubar {
    display: block;
  }

  .desktop-view-toggle-menubar {
    display: none;
  }

  .Header {
    padding: 5px 0px 5px 0px;
  }

  .Header .menu-header-holder .menu-header {
    margin: 0px 0px 0px auto;
  }

  .Header .Header_holder .logo-holder {
    text-align: center;
  }

  .Header .Header_holder .logo-holder .logo-img {
    width: 100%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 100%;
  }

  .Header .Header_holder .icon-text-holder {
    height: 100%;
  }

  .Header .menu-header-holder .menu-header .nav-link {
    font-size: 12px;
    margin: 0px;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Header .menu-header-holder .menu-header .nav-link {
    margin: 0px 25px;
    font-size: 15px;
  }
  .Header .Header_holder .icon-text-holder {
    height: 75%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 75%;
  }

  .Header .Header_holder .logo-holder .logo-img {
    width: 85%;
  }
}

@media (min-width: 1024px) and (max-width: 1299px) {
  .Header .menu-header-holder .menu-header .nav-link {
    margin: 0px 25px;
    font-size: 15px;
  }
  .Header .Header_holder .icon-text-holder {
    height: 75%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 75%;
  }

  .Header .Header_holder .logo-holder .logo-img {
    width: 85%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .Header .menu-header-holder .menu-header .nav-link {
    margin: 0px 25px;
    font-size: 15px;
  }
  .Header .Header_holder .icon-text-holder {
    height: 75%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 75%;
  }

  .Header .Header_holder .logo-holder .logo-img {
    width: 85%;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  .Header .menu-header-holder .menu-header .nav-link {
    margin: 0px 25px;
    font-size: 15px;
  }
  .Header .Header_holder .icon-text-holder {
    height: 75%;
  }

  .Header .Header_holder .social-icon-holder {
    height: 75%;
  }

  .Header .Header_holder .logo-holder .logo-img {
    width: 85%;
  }
}
