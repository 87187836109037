.Home_About_Us {
  margin-top: 5%;
}

.Home_About_Us
  .Home_About_Us_holder
  .Home_About_Us_content_holder
  .heading-holder {
  margin-bottom: 5%;
}


.Home_About_Us
  .Home_About_Us_holder
  .Home_About_Us_content_holder
  .text-holder {
  margin-bottom: 5%;
}



.Home_About_Us
  .Home_About_Us_holder
  .Home_About_Us_content_holder
  .btn-holder
  .know-more-btn {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  background: #000000;
  border: 1px solid #fff;
  border-radius: 5px;
}

.Home_About_Us .Home_About_Us_holder .Home_About_Us_img_holder {
  border-radius: 25px;
  position: relative;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Home_About_Us .Home_About_Us_holder .Home_About_Us_img_holder:after {
  content: "";
  position: absolute;
  right: 0px;
  border-right: 130px solid #0B6E99;
  height: 100%;
  z-index: -1;
  border-radius: 0px 25px 25px 0px;
}

.Home_About_Us
  .Home_About_Us_holder
  .Home_About_Us_img_holder
  .Home_About_Us_img {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}


