.keep-counter-section {
  margin: 5% 0;
  background-color: #f5f5f5;
  padding: 40px;
}

.keep-counter-section .heading-name h2 {
  font-size: 36px;
  font-weight: bold;
}

.keep-counter-section .heading-name {
  margin-bottom: 20px;
}

.keep-counter-section .counter-box {
  background: #abe1db 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 80px 20px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keep-counter-section .counter-box2 {
  background: #b9d7e3 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 80px 20px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keep-counter-section .counter-box3 {
  background: #c8e2f6 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 80px 20px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.keep-counter-section .overlay1 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(90deg, #abe1db 0%, #10897d 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.3;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.keep-counter-section .counter-box:hover .overlay1 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.keep-counter-section .overlay2 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(90deg, #add5e6 0%, #0b6e99 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.3;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.keep-counter-section .counter-box2:hover .overlay2 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.keep-counter-section .overlay3 {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent linear-gradient(90deg, #add5e6 0%, #0081e6 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 10px;
  opacity: 0.3;
  overflow: hidden;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

.keep-counter-section .counter-box3:hover .overlay3 {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.keep-counter-section .total-holder {
  display: flex;
  justify-content: center;
  align-items: end;
  position: absolute;
  z-index: 1;
}

.keep-counter-section .total-holder h4 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #000;
}

.keep-counter-section .total-holder p {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0%;
  color: #000;
}

@media (min-width: 0px) and (max-width: 767px) {
  .keep-counter-section .heading-name h2 {
    font-size: 20px;
  }

  .keep-counter-section .total-holder p {
    font-size: 18px;
  }

  .keep-counter-section .total-holder h4 {
    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .keep-counter-section .heading-name h2 {
    font-size: 25px;
  }

  .keep-counter-section .total-holder p {
    font-size: 20px;
  }

  .keep-counter-section .total-holder h4 {
    font-size: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .keep-counter-section .heading-name h2 {
    font-size: 31px;
  }

  .keep-counter-section .total-holder p {
    font-size: 20px;
  }

  .keep-counter-section .total-holder h4 {
    font-size: 34px;
  }
}
