.Why_Choose_Us {
  position: relative;
}

.Why_Choose_Us:after {
  position: absolute;
  content: "";
  top: 0px;
  right: 0px;
  /* border-right: 1000px solid #0b6e99; */
  height: 100%;
  width: 35%;
  background: #0b6e99;
  z-index: -1;
  border-bottom-left-radius: 25px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Why_Choose_Us_img_holder {
  padding-bottom: 10%;
}

.Why_Choose_Us
  .Why_Choose_Us_holder
  .Why_Choose_Us_img_holder
  .Why_Choose_Us_img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 25px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder {
  padding-bottom: 50px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .heading-holder {
  margin-bottom: 20px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .heading-holder h3 {
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: #202020;
  position: relative;
}

.Why_Choose_Us
  .Why_Choose_Us_holder
  .Benifits-holder
  .heading-holder
  h3::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #0b6e99;
  border-right: 10px solid transparent;
  position: absolute;
  content: "";
  top: 0px;
  left: -25px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .text-holder p {
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #636363;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.Why_Choose_Us_content_holder .content-text {
  margin-top: 30px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-first {
  position: relative;
}

.Why_Choose_Us
  .Why_Choose_Us_holder
  .Benifits-holder
  .content-holder-first:after {
  position: absolute;
  content: "";
  border-bottom: 1px dashed #707070;
  bottom: 0px;
  right: 0px;
  width: 100px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-second {
  position: relative;
  padding-left: 30px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder {
  margin-top: 60px;
}

.Why_Choose_Us
  .Why_Choose_Us_holder
  .Benifits-holder
  .content-holder-second:after {
  position: absolute;
  content: "";
  border-left: 1px dashed #707070;
  bottom: 0px;
  left: -1px;
  height: 100px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-third {
  position: relative;
  padding-top: 50px;
}

.Why_Choose_Us
  .Why_Choose_Us_holder
  .Benifits-holder
  .content-holder-third:after {
  position: absolute;
  content: "";
  border-left: 1px dashed #707070;
  top: 0px;
  right: 0px;
  height: 100px;
}

.Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-four {
  position: relative;
  padding-top: 50px;
  padding-left: 30px;
}

.Why_Choose_Us
  .Why_Choose_Us_holder
  .Benifits-holder
  .content-holder-four:after {
  position: absolute;
  content: "";
  border-bottom: 1px dashed #707070;
  top: -1px;
  left: 0px;
  width: 100px;
}

@media (min-width: 0px) and (max-width: 1023px) {
  .Why_Choose_Us:after {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .Why_Choose_Us .Why_Choose_Us_holder .Why_Choose_Us_img_holder {
    text-align: center;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Why_Choose_Us_img_holder
    .Why_Choose_Us_img {
    width: 50%;
  }
}

@media (min-width: 0px) and (max-width: 320px) {
  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-first:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-second:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-third:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-four:after {
    display: none;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-third {
    padding-top: 0px;
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-four {
    padding-top: 0px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-first {
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder {
    padding-bottom: 15px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder {
    margin-top: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .heading-holder {
    margin-bottom: 5px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-first:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-second:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-third:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-four:after {
    display: none;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-third {
    padding-top: 0px;
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-four {
    padding-top: 0px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-first {
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder {
    padding-bottom: 15px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder {
    margin-top: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .heading-holder {
    margin-bottom: 5px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-first:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-second:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-third:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-four:after {
    display: none;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-third {
    padding-top: 0px;
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-four {
    padding-top: 0px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-first {
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder {
    padding-bottom: 15px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder {
    margin-top: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .heading-holder {
    margin-bottom: 5px;
  }
}

/* @media (min-width: 576px) and (max-width: 677px) {
  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-first:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-second:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-third:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-four:after {
    display: none;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-third {
    padding-top: 0px;
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-four {
    padding-top: 0px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-first {
    padding-left: 30px;
  }
} */

/* @media (min-width: 678px) and (max-width: 767px) {
  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-first:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-second:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-third:after {
    display: none;
  }

  .Why_Choose_Us
    .Why_Choose_Us_holder
    .Benifits-holder
    .content-holder-four:after {
    display: none;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-third {
    padding-top: 0px;
    padding-left: 30px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-four {
    padding-top: 0px;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder-first {
    padding-left: 30px;
  }
} */

@media (min-width: 1024px) and (max-width: 1199px) {
  .Why_Choose_Us:after {
    border-right: 250px solid #0b6e99;
  }

  .Why_Choose_Us .Why_Choose_Us_holder .Benifits-holder .content-holder {
    padding-bottom: 17px;
  }
}
