/*Home-banner css start  */

.Banner-holder {
  position: relative;
  /* height: 100vh; */
  margin-top: -140px;
}

.Banner-holder .Home-banner .Banner {
  position: relative;
}

.Banner-holder .Home-banner .Banner .banner-image-holder .banner-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/0.48;
}

.Banner-holder .Home-banner .Banner .banner-text-holder {
  position: absolute;
  /* top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 0px;
  right: 0px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.Banner-holder .Home-banner .Banner .banner-text-holder h3 {
  font-size: 56px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0px;
}

.Banner-holder .Home-banner .Banner .banner-text-holder span {
  color: #fff;
  font-size: 33px;
  text-align: center;
  font-weight: 400;
}

.Banner-holder .Home-banner {
  /* position: absolute; */
  /* top: -20px; */
}

/*Home-banner css end  */

/* counter-holder css start */

.Banner-holder .counter-holder .counter-heading-holder {
  display: flex;
  align-items: end;
  height: 100%;
}

.Banner-holder .counter-holder .counter-heading-holder h3 {
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
  color: #202020;
}

.Banner-holder .counter-holder .count-holder h3 {
  text-align: left;
  font-size: 36px;
  font-weight: 700;
  color: #202020;
  margin-bottom: 0px;
}

.Banner-holder .counter-holder {
  position: absolute;
  bottom: 50px;
  left: 0px;
  width: 100%;
  z-index: 2;
}

.Banner-holder .counter-holder .count-holder {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 100%;
}

/* counter-holder css end */

@media (min-width: 0px) and (max-width: 320px) {
  .Banner-holder .counter-holder {
    bottom: -5px;
  }

  .Banner-holder {
    margin-top: -20px;
  }

  .Banner-holder .counter-holder .counter-heading-holder {
    text-align: center;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 9px;
    font-weight: 700;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 10px;
    font-weight: 900;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 13px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 15px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Banner-holder {
    margin-top: -30px;
  }
  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 13px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 15px;
  }

  .Banner-holder .counter-holder {
    bottom: -5px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 10px;
    font-weight: 700;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 12px;
    font-weight: 700;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 20px;
  }

  .Banner-holder {
    margin-top: -35px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 20px;
  }

  .Banner-holder .counter-holder {
    bottom: -5px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 12px;
    font-weight: 700;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 15px;
    font-weight: 900;
  }
}

@media (min-width: 576px) and (max-width: 677px) {
  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 20px;
  }

  .Banner-holder {
    margin-top: -45px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 25px;
  }

  .Banner-holder .counter-holder {
    bottom: -5px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 15px;
    font-weight: 700;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 18px;
    font-weight: 900;
  }
}

@media (min-width: 678px) and (max-width: 767px) {
  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 20px;
  }

  .Banner-holder {
    margin-top: -50px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 25px;
  }

  .Banner-holder .counter-holder {
    bottom: 10px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 14px;
    font-weight: 700;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 18px;
    font-weight: 900;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 20px;
  }

  .Banner-holder {
    margin-top: -65px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 30px;
  }

  .Banner-holder .counter-holder {
    bottom: 20px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 12px;
    font-weight: 700;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 18px;
    font-weight: 900;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Banner-holder .Home-banner .Banner .banner-text-holder span {
    font-size: 20px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 35px;
  }

  .Banner-holder .counter-holder {
    bottom: 30px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 14px;
    font-weight: 600;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 18px;
    font-weight: 900;
  }
}

@media (min-width: 1024px) and (max-width: 1299px) {
  .Banner-holder .counter-holder {
    bottom: 20px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 40px;
  }

  .Banner-holder .counter-holder .count-holder h3 {
    font-size: 22px;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 17px;
  }
}

@media (min-width: 1300px) and (max-width: 1440px) {
  .Banner-holder .counter-holder {
    bottom: 20px;
  }

  .Banner-holder .Home-banner .Banner .banner-text-holder h3 {
    font-size: 50px;
  }
}

@media (min-width: 2000px) and (max-width: 3000px) {
  .Banner-holder {
    margin-top: -200px;
  }

  .Banner-holder .Home-banner .Banner .banner-image-holder .banner-image {
    aspect-ratio: 1/0.45;
  }

  .Banner-holder .counter-holder .counter-heading-holder h3 {
    font-size: 30px;
}
}
