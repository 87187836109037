.Footer {
  background: #0b6e99;
  padding: 0% 2% 0% 2%;
}

.Footer .footer-logo-holder .logo-holder {
  text-align: center;
}

.Footer .footer-logo-holder {
  background-image: url("/public/Assets/Images/Footer/Footer-logo-bg.png");
  background-size: contain;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.Footer .footer-logo-holder .logo-holder .logo-img {
  width: 30%;
  height: 50%;
  aspect-ratio: 1/0.7;
  margin: 0% 0%;
  padding: 27px 0px;
}

.Footer .footer-menu ul {
  display: flex;
  flex-wrap: nowrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #fff;
}

.Footer .footer-menu li {
  list-style: none;
}

.Footer .footer-menu .menu-holder {
  margin: 10px 19px;
  cursor: pointer;
}

.Footer .footer-menu .menu-holder p {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #ffffff;
}

.Footer .contact-info-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer .address-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer .footer-info-holder .info-holder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer .footer-info-holder .info-content-holder {
  margin-top: 30px;
}

.Footer .footer-info-holder .info-holder p {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;
}

.Footer .footer-info-holder .icon-holder {
  background: #fff;
  width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 8px;
  margin-right: 8px;
}



.Footer .footer-info-holder .icon-holder .footer-icon {
  color: #0b6e99;
  background: transparent;
  font-size: 11px;
  margin-bottom: 2px;
}

.Footer .footer-info-holder .footer-social-icon-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}


.Footer .footer-info-holder .footer-social-icon-holder  .icon-holder:hover {
  background: transparent;
  border: 1px solid #fff;
}

.Footer .footer-info-holder .footer-social-icon-holder .icon-holder:hover  .footer-icon {
  color: #fff;
}

.Footer .footer-copyright-holder {
  background: #065577 0% 0% no-repeat padding-box;
  padding: 15px 0px;
  margin-top: 30px;
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  margin: 30px auto 0px auto;
}

.Footer .footer-copyright-holder p {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 0px;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 20%;
    padding: 5px 0px;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 20%;
    padding: 5px 0px;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 20%;
    padding: 15px 0px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 20%;
    padding: 15px 0px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 35%;
    padding: 15px 0px;
}
}

@media (min-width: 992px) and (max-width: 1023px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 25%;
    padding: 15px 0px;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .Footer .footer-logo-holder .logo-holder .logo-img {
    width: 30%;
    padding: 20px 0px;
}
}

