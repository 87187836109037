.Trusted-Platform {
  margin: 5% 0;
}

/* .Trusted-Platform .heading-holder h2 {
    color: #0B6E99;
    font-size: 18px;
    font-weight: 600;
} */

/* .Trusted-Platform .heading-holder h2::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background-color: #0B6E99;
    position: absolute;
    left: 26%;
    z-index: -2;
    margin-top: -12px;
} */

/* .Trusted-Platform .heading-holder h3 {
    font-size: 40px;
    font-weight: bold;
} */

.Trusted-Platform .solution-box {
  background: #10897d;
  /* Solid background color */
  border-radius: 5px;
  position: relative;
  padding: 15px 10px 15px 60px;
  background-position: right bottom;
  transition: background-position 0.3s ease-in-out, color 0.3s ease-in-out;
  /* overflow: hidden; */
  background-size: 200% 100%;
  position: relative;
  height: 100px;
}

.Trusted-Platform .solution-box::before {
  content: "";
  height: 100%;
  position: absolute;
  width: 0px;
  top: 0;
  background: linear-gradient(270deg, #10897d 0%, #00342f 100%);
  left: 0px;
  transition: 0.3s ease-in-out;
  z-index: 0;
  border-radius: 5px;
}

.Trusted-Platform .solution-box:hover::before {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0px;
  transition: 0.3s ease-in-out;
}

/* .Trusted-Platform .solution-box:hover {
    background: linear-gradient(270deg, #10897D 0%, #00342F 100%);
    background-position: left bottom;
    border-radius: 5px;
} */

.Trusted-Platform .solution-box .solution-box-text-holder {
    top: 50%;
    position: absolute;
    /* bottom: 50%; */
    transform: translateY(-50%);
}

.Trusted-Platform .solution-box h3 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.Trusted-Platform .solution-box p {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0%;
}

.Trusted-Platform .solution-box .circle-box {
  background: #0b6e99;
  border: 5px solid #fff;
  border-radius: 50px;
  position: absolute;
  width: 58px;
  height: 58px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  left: -34px;
}

.Trusted-Platform .solution-box .circle-box .circle-image {
  margin-top: 10px;
}

@media (min-width: 0px) and (max-width: 359px) {
  /* .Trusted-Platform .heading-holder h2::after {
        left: 55% !important;
    } */
}

@media (min-width: 360px) and (max-width: 420px) {
  /* .Trusted-Platform .heading-holder h2::after {
        left: 47% !important;
    } */
}

@media (min-width: 420px) and (max-width: 567px) {
  /* .Trusted-Platform .heading-holder h2::after {
        left: 42% !important;
    } */
}

@media (min-width: 0px) and (max-width: 767px) {
  .Trusted-Platform .heading-holder h3 {
    font-size: 20px;
  }

  .Trusted-Platform .heading-holder h2 {
    font-size: 16px;
  }

  .Trusted-Platform .heading-holder p {
    font-size: 14px;
  }

  .Trusted-Platform .solution-box h3 {
    font-size: 17px;
  }

  .Trusted-Platform .solution-box p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0%;
  }

  .Trusted-Platform .solution-box {
    margin: 0px 20px;
  }

  .Trusted-Platform .heading-holder h2::after {
    /* left: 37%; */
    /* display: none; */
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .Trusted-Platform .heading-holder h3 {
    font-size: 23px;
  }

  .Trusted-Platform .heading-holder p {
    font-size: 14px;
  }

  /* .Trusted-Platform .heading-holder h2::after {
        left: 30%;
    } */

  .Trusted-Platform .solution-box h3 {
    font-size: 17px;
  }

  .Trusted-Platform .solution-box p {
    font-size: 15px;
    color: #fff;
    margin-bottom: 0%;
  }

  .Trusted-Platform .solution-box {
    margin: 0px 0px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .Trusted-Platform .heading-holder h3 {
    font-size: 27px;
  }

  .Trusted-Platform .solution-box h3 {
    font-size: 20px;
  }

  .Trusted-Platform .solution-box p {
    font-size: 17px;
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  /* .Trusted-Platform .heading-holder h2::after {
        left: 20%;
    } */
}
