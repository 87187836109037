.about-platform {
    margin: 2% 0;
}

.about-platform .heading-holder h2 {
    font-size: 18px;
    color: #0B6E99;
    font-weight: 600;
    margin-bottom: 15px;
}

.about-platform .heading-holder h3 {
    color: #202020;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px;
}

.about-platform .heading-holder p {
    color: #202020;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

@media(min-width:0px) and (max-width:767px) {
    .about-platform .heading-holder h3 {
        font-size: 20px;
    }

    .about-platform .heading-holder p {

        font-size: 14px;

    }
}

@media(min-width:768px) and (max-width:922px) {
    .about-platform .heading-holder h3 {
        font-size: 25px;
    }

    .about-platform .heading-holder p {

        font-size: 14px;

    }
}

@media(min-width:992px) and (max-width:1200px) {
    .about-platform .heading-holder h3 {
        font-size: 30px;
    }

    .about-platform .heading-holder p {

        font-size: 14px;

    }
}